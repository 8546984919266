import { PdfService } from './services/pdf/pdf.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginService } from './services/login/login.service';
import { RegisterService } from './services/register/register.service';
import { SelectsService } from './services/selects/selects.service';
import { ProfileService } from './services/profile/profile.service';
import { FasesService } from './services/fases/fases.service';
import { VocacionalService } from './services/tests/vocacional/vocacional.service';
import { DiagnosticoService } from './services/tests/diagnostico/diagnostico.service';
import { PreseleccionadosService } from './services/preseleccionados/preseleccionados.service';
import { SafePipe } from './pipes/safe.pipe';
import { SpinnerService } from './services/spinner/spinner.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    AppRoutingModule
  ],
  exports: [
  ],
  providers: [
    LoginService,
    RegisterService,
    SelectsService,
    ProfileService,
    FasesService,
    VocacionalService,
    DiagnosticoService,
    PreseleccionadosService,
    SpinnerService,
    PdfService,
    LocalStorageService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
