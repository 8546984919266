export class FasesConfig {
    public static INSCRIPCIONES : number = 1;
    public static TEST_VOCACIONAL : number = 2;
    public static PUBLICACIONES_PRESELECCION: number = 3;
    public static VALIDACION: number = 4;
    public static PUBLICACIONES_PRESELECCION_II: number = 5;
    public static TEST_DIAGNOSTICO: number = 6;
    public static PUBLICACIONES_SELECCIONADOS: number = 7;
    public static CLOSE: number = 8;
    public static EVERCLOSE: number = 9;

}
