import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../login/login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreseleccionadosService {

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getList() : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    return this.http.post(`${environment.URL_API}/admin/getListado`, null, {headers : headers});
  }

  getAspirante(cedula: string) : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('cedula', cedula);

    return this.http.post(`${environment.URL_API}/admin/getAspirante`, null, {headers : headers, params: params});
  }

  verificar(cedula: string) : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('cedula', cedula);

    return this.http.post(`${environment.URL_API}/admin/verificar`, null, {headers : headers, params: params});
  }

  reject(user_id: number, observaciones?: string, revisiones?: string) : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    let params = new HttpParams()
    .append('user_id', String(user_id))
    .append('estado', '0');

    if(observaciones != null && observaciones != undefined) {
      params = params.append('observaciones', observaciones);
    }

    if(revisiones != null && revisiones != undefined) {
      params = params.append('revisiones', revisiones);
    }

    return this.http.post(`${environment.URL_API}/admin/validar`, null, {headers : headers, params: params});
  }

  approved(user_id: number, observaciones?: string, revisiones?: string) : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());
    let params = new HttpParams()
    .append('user_id', String(user_id))
    .append('estado', '1');

    if(observaciones != null && observaciones != undefined) {
      params = params.append('observaciones', observaciones);
    }

    if(revisiones != null && revisiones != undefined) {
      params = params.append('revisiones', revisiones);
    }

    return this.http.post(`${environment.URL_API}/admin/validar`, null, {headers : headers, params: params});
  }
}

