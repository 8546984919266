import { Injectable } from '@angular/core';
import { OtherConfig } from 'src/app/app.other.config';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setItem(key: string, value: any) : void {
    localStorage.setItem(`${OtherConfig.LocalStorage}${key}`, JSON.stringify(value));
  }

  getItem(key: string) : any {
    return JSON.parse(localStorage.getItem(`${OtherConfig.LocalStorage}${key}`));
  }

  removeItem(key: string) : void {
    localStorage.removeItem(`${OtherConfig.LocalStorage}${key}`);
  }

  clear() {
    localStorage.clear();
  }
}
