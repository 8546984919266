import { Injectable } from '@angular/core';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { OtherConfig } from 'src/app/app.other.config';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(
  ) {
  }

  public downloadPDF(data: any) : void {


    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;

      let contentDataURL = canvas.toDataURL('image/png');

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      let fileName = this.getFileName();
      pdf.save(`${fileName}.pdf`); // Generated PDF
    });
  }

  private getFileName() : string {
    var currentdate = new Date();
    var fileName = currentdate.getDate()
                    + '' + (currentdate.getMonth()+1)
                    + '' + currentdate.getFullYear()
                    + '' + currentdate.getHours()
                    + '' + currentdate.getMinutes()
                    + '' + currentdate.getSeconds();
    return fileName;
  }

}
