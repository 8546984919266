import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {


  constructor(public http: HttpClient) {

  }

  doRegister(form: any) : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json');
    
    const params = new HttpParams()
    .append('username', form.username)
    .append('email', form.email)
    .append('password', form.password);

    return this.http.post(`${environment.URL_API}/user/register`, null, {headers : headers, params : params});
  }
  
}
