import { IFase } from './../../models/fase.model';
import { FasesConfig } from './../../app.fases.config';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FasesService {

  private fase : BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private http: HttpClient
    ) {
  }

  public loadCurrentFase() : Promise<any> {
    return new Promise((resolve, reject) =>{
      this.http.post(`${environment.URL_API}/getCurrentFase`, null)
      .subscribe((response : any) => {
        this.fase.next(response.fase);
        resolve(true);
      }, (error: any) => {
        this.fase.next({id: FasesConfig.CLOSE});
        resolve(false);
      });
    });
  }

  /*public loadCurrentFase() : Promise<any> {
    return new Promise((resolve, reject) =>{
      let f : IFase = { id: FasesConfig.CLOSE};
      this.fase.next(f);
      console.log(f);
      resolve(true);
    });
  }*/


  getCurrentFase() : any {
    return this.fase;
  }

}
