import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../login/login.service';
import { environment } from 'src/environments/environment';
import { ICarrera } from 'src/app/models/carrera.model';
import { IMateria } from 'src/app/models/materia.model';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient, private loginService: LoginService, private localStorageService: LocalStorageService) {

  }

  getProfile() : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    return this.http.post(`${environment.URL_API}/users/profile/getProfile`, null, {headers : headers});

  }

  getProfileOpciones() : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    return this.http.post(`${environment.URL_API}/users/profile/getOptions`, null, {headers : headers});
  }

  updateProfile(data: any) {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('nombre', data.nombre)
    .append('apellido', data.apellido)
    .append('cedula', data.cedula)
    .append('telefono', data.telefono)
    .append('fecha_nacimiento', data.fecha_nacimiento)
    .append('year', data.year)
    .append('genero', data.genero)
    .append('especialidad_id', data.especialidad_id)
    .append('estado', data.estado)
    .append('municipio', data.municipio)
    .append('parroquia', data.parroquia);

    return this.http.post(`${environment.URL_API}/users/profile/update`, null, {headers : headers, params : params});

  }

  validateDni(data: any) {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('cedula', data.cedula);

    return this.http.post(`${environment.URL_API}/users/profile/validateDni`, null, {headers : headers, params : params});

  }

  updateAcademicos(data: any) {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('type_liceo', data.type_liceo)
    .append('liceo', data.liceo)
    .append('liceo_estado', data.liceo_estado)
    .append('liceo_municipio', data.liceo_municipio)
    .append('liceo_parroquia', data.liceo_parroquia);

    return this.http.post(`${environment.URL_API}/users/profile/academic`, null, {headers : headers, params : params});
  }

  updateAcademicosNotas(data: any) {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('notas', data.notas);

    this.localStorageService.setItem('notas', JSON.stringify(data.notas));
    return this.http.post(`${environment.URL_API}/users/profile/academic-notas`, null, {headers : headers, params : params});
  }

  updateSeleccion(data: any) {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('opciones', data.opciones);

    this.localStorageService.setItem('opciones', data.opciones);
    return this.http.post(`${environment.URL_API}/users/profile/select`, null, {headers : headers, params : params});
  }

  updateComprobante(data: any) {

    const headers = new HttpHeaders()
    .append('Content-Type', 'multipart/form-data')
    .append('Accept', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('vouche_number', data.vouche_number)
    .append('vouche_fecha', data.vouche_fecha)
    .append('vouche_type', data.vouche_type)
    .append('vouche_banco', data.vouche_banco)
    .append('filename', data.filename)
    .append('filetype', data.filetype)
    .append('vouche_file', data.vouche_file);

    return this.http.post(`${environment.URL_API}/users/profile/comprobante`, null, {headers : headers, params : params});
  }

  readFile(data: any, field: string) {
    const formData = new FormData();
    formData.append(field, data);

    return this.uploadFiles(formData);
  }

  uploadFiles(params?: any) {

    const headers = new HttpHeaders()
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    return this.http.post(`${environment.URL_API}/files/files`, params, {headers : headers, params : params});
  }

  generatePromedios() : any {
    let auxNotas : any = JSON.parse(this.localStorageService.getItem('notas'));
    let opciones : ICarrera[] = JSON.parse(this.localStorageService.getItem('opciones'));
    let promedios = {general: 0.0, opcion_1: 0.0, opcion_2: 0.0, opcion_3: 0.0};

    if(auxNotas != undefined && opciones != undefined) {

      let notas : any = JSON.parse(auxNotas);
      let count : number = 0;
      let sum : number = 0.0;

      notas.ano1.forEach(nota => {
          sum += parseFloat(nota.nota);
          count++;
      });

      notas.ano2.forEach(nota => {
        sum += parseFloat(nota.nota);
        count++;
      });

      notas.ano3.forEach(nota => {
        sum += parseFloat(nota.nota);
        count++;
      });

      notas.ano4.forEach(nota => {
        sum += parseFloat(nota.nota);
        count++;
      });

      let opcion_1: number = this.calculateProd(notas, opciones[0]);

      let opcion_2: number = 0.0;
      if(opciones.length >= 2) {
        opcion_2 = this.calculateProd(notas, opciones[1]);
      }

      let opcion_3: number = 0.0;
      if(opciones.length >= 3) {
        opcion_3 = this.calculateProd(notas, opciones[2]);
      }

      promedios = {general: (sum / count), opcion_1: opcion_1, opcion_2: opcion_2, opcion_3: opcion_3};

    }

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('promedios', JSON.stringify(promedios));

    return this.http.post(`${environment.URL_API}/users/profile/promedios`, null, {headers : headers, params : params});
  }

  private calculateProd(notas: any, carrera: ICarrera) : number {
    let carrerasPeriodos = JSON.parse(this.localStorageService.getItem('carrerasPeriodos'));
    let carrerasPeriodo: any = null;
    let materias: IMateria[] = JSON.parse(this.localStorageService.getItem('materias'));
    let count: number = 0;
    let sum: number = 0.0;

    for(let i = 0; i < carrerasPeriodos.length; ++i) {
      if (carrerasPeriodos[i].carrera_id == carrera.id) {
        carrerasPeriodo = carrerasPeriodos[i];
        break;
      }
    }

    carrerasPeriodo.materias_importantes.split(',').forEach(importante => {
      let materia: IMateria;
      for(let i = 0; i < materias.length; ++i) {
        if(materias[i].codigo == importante) {
          materia = materias[i];
          break;
        }
      }

      notas.ano1.forEach(nota => {
        if(nota.nombre == materia.nombre) {
          sum += parseFloat(nota.nota);
          count++;
        }
      });

      notas.ano2.forEach(nota => {
        if(nota.nombre == materia.nombre) {
          sum += parseFloat(nota.nota);
          count++;
        }
      });

      notas.ano3.forEach(nota => {
        if(nota.nombre == materia.nombre) {
          sum += parseFloat(nota.nota);
          count++;
        }
      });

      notas.ano4.forEach(nota => {
        if(nota.nombre == materia.nombre) {
          sum += parseFloat(nota.nota);
          count++;
        }
      });
    });

    if(count == 0) {
      return 0.0;
    }

    return (sum/count);
  }

}
