import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../../login/login.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from '../../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticoService {

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private loginService: LoginService,
    private sanitizer:DomSanitizer
    ) {

  }

  getStatustest() : Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this.loginService.getToken());

        return this.http.post(`${environment.URL_API}/users/tests/getStatusTestDiagnostico`, null, {headers : headers}).subscribe((response : any) => {
          this.localStorageService.setItem('estado_prueba', response.data.estado_prueba);
          this.localStorageService.setItem('seccion_verbal', response.data.seccion_verbal);
          this.localStorageService.setItem('seccion_logico', response.data.seccion_logico);
          resolve(response);
        });
    });
  }

  setTestDiagnostico() : Promise<any> {
    return new Promise((resolve, reject) => {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

      return this.http.post(`${environment.URL_API}/users/tests/setTestDiagnostico`, null, {headers : headers}).subscribe((response : any) => {
        this.localStorageService.setItem('inicio_verbal', response.data.inicio_verbal);
        this.localStorageService.setItem('fin_verbal', response.data.fin_verbal);
        this.localStorageService.setItem('inicio_logico', response.data.inicio_logico);
        this.localStorageService.setItem('fin_logico', response.data.fin_logico);
        this.localStorageService.setItem('seccion_verbal', response.data.seccion_verbal);
        this.localStorageService.setItem('seccion_logico', response.data.seccion_logico);
        this.localStorageService.setItem('permutacion', response.data.permutacion);
        this.localStorageService.setItem('estado_prueba', response.data.estado_prueba);

        resolve(response);
      });
    });
  }

  updateVocacional(data: any) : any {

    this.localStorageService.setItem('respuestas', JSON.stringify(data.respuestas));

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('respuestas', JSON.stringify(data.respuestas))
    .append('page', JSON.stringify(data.page))
    .append('finalizado', data.finalizado);

    return this.http.post(`${environment.URL_API}/users/tests/updateVocacional`, null, {headers : headers, params : params});

  }

  updateSection(section: number) : Promise<any> {
    return new Promise((resolve, reject) => {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('seccion', String(section));

      return this.http.post(`${environment.URL_API}/users/tests/sections`, null, {headers : headers, params: params}).subscribe((response : any) => {
        this.localStorageService.setItem('inicio_verbal', response.data.test.inicio_verbal);
        this.localStorageService.setItem('fin_verbal', response.data.test.fin_verbal);
        this.localStorageService.setItem('inicio_logico', response.data.test.inicio_logico);
        this.localStorageService.setItem('fin_logico', response.data.test.fin_logico);
        this.localStorageService.setItem('seccion_verbal', response.data.test.seccion_verbal);
        this.localStorageService.setItem('seccion_logico', response.data.test.seccion_logico);
        this.localStorageService.setItem('permutacion', response.data.test.permutacion);
        this.localStorageService.setItem('estado_prueba', response.data.test.estado_prueba);
        this.localStorageService.setItem('preguntas_verbal', JSON.stringify(response.data.pregunta));
        this.localStorageService.setItem('estructura', JSON.stringify(response.data.estructura));
        resolve(response);
      });
    });
  }

  updateVerbalTest(numero: number, respuesta: string) : Promise<any> {
    return new Promise((resolve, reject) => {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('numero', String(numero))
    .append('respuesta', respuesta);

      return this.http.post(`${environment.URL_API}/users/tests/updateVerbalTest`, null, {headers : headers, params: params}).subscribe((response : any) => {
        resolve(response);
      });
    });

  }

  updateLogicoTest(numero: number, respuesta: string) : Promise<any> {
    return new Promise((resolve, reject) => {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('numero', String(numero))
    .append('respuesta', respuesta);

      return this.http.post(`${environment.URL_API}/users/tests/updateLogicolTest`, null, {headers : headers, params: params}).subscribe((response : any) => {
        resolve(response);
      });
    });

  }

  setFinishVerbal() : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    return this.http.post(`${environment.URL_API}/users/tests/setFinishVerbal`, null, {headers : headers})
  }

  setFinishLogico() : any {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    return this.http.post(`${environment.URL_API}/users/tests/setFinishLogico`, null, {headers : headers,});
  }

  getRelojVerbal() : any {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    return this.http.post(`${environment.URL_API}/users/tests/getRelojVerbal`, null, {headers : headers})
  }

  getRelojLogico() : any {

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    return this.http.post(`${environment.URL_API}/users/tests/getRelojLogico`, null, {headers : headers,});
  }

  getImagebase64(imgUrl) : Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('call getImagebase64', imgUrl);



    });
  }
}
