import { IFase } from './../models/fase.model';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';
import { FasesService } from '../services/fases/fases.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from '../services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate  {

  private fase : IFase = null;

  constructor(
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
     private router: Router,
      private faseService : FasesService,
      private deviceService: DeviceDetectorService
      ) {
        if(this.deviceService.isMobile()){
          this.router.navigate(['/']);
        }
    this.faseService.getCurrentFase()
    .subscribe((fase : IFase) =>  {
      this.fase = fase;
    });
  }

  canActivate(): boolean {
    if (!this.loginService.isAuthenticated()) {
      this.router.navigate(['/']);
      return false;
    }

    if((this.fase === null) || (this.fase === undefined) || (this.fase.id >= 9)) {
      this.localStorageService.clear();
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }

}
