import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../../login/login.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class VocacionalService {

  constructor(private localStorageService: LocalStorageService, private http: HttpClient, private loginService: LoginService) {

  }

  getVocacional() {
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    return this.http.post(`${environment.URL_API}/users/tests/getVocacional`, null, {headers : headers}).subscribe((response : any) =>{
      this.localStorageService.setItem('current_vocacional', JSON.stringify(response.data.current));
      this.localStorageService.setItem('respuestas', response.data.current.respuestas);
      this.localStorageService.setItem('page', response.data.current.page);
      this.localStorageService.setItem('finish', JSON.stringify(response.data.current.finalizado));
    });

  }

  updateVocacional(data: any) {

    this.localStorageService.setItem('respuestas', JSON.stringify(data.respuestas));

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append('Authorization', 'Bearer ' + this.loginService.getToken());

    const params = new HttpParams()
    .append('respuestas', JSON.stringify(data.respuestas))
    .append('page', JSON.stringify(data.page))
    .append('finalizado', data.finalizado);

    return this.http.post(`${environment.URL_API}/users/tests/updateVocacional`, null, {headers : headers, params : params});

  }
}
